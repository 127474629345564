import { Container } from '@material-ui/core';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import MarginTopContainer from '../components/MarginTopContainer';
import SEO from '../components/SEO';

type DataProps = Record<string, unknown>;

const CookiesPage: FC<PageProps<DataProps>> = () => {
  return (
    <Layout>
      <MarginTopContainer>
        <Container>
          <h3>Legal</h3>
          <SEO title="Legal - Půjčovna kol BeRider" />
          {/*OneTrust Cookies Settings button start*/}
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            Cookie Settings
          </button>
          {/*OneTrust Cookies Settings button end*/}

          {/*OneTrust Cookies List start*/}
          <div id="ot-sdk-cookie-policy"></div>
          {/*OneTrust Cookies List end*/}
        </Container>
      </MarginTopContainer>
    </Layout>
  );
};

export default CookiesPage;
